
import { Directive, Input, OnInit, HostListener } from '@angular/core';

import animateScrollTo from 'animated-scroll-to';

@Directive({
  selector: '[clvScrollTo]'
})
export class ScrollToDirective implements OnInit {

    /**
     * [Input DOM element used to scroll to]
     * @return {[type]} [description]
     */
    @Input()
    domId: string;
    @Input()
    triggerInitialScroll: boolean;

    scrollOptions: object;

    constructor() { }

    ngOnInit() {

        this.scrollOptions = {
            speed: 500,
            minDuration: 250,
            maxDuration: 3000,
            cancelOnUserAction: true
        };

        if (this.triggerInitialScroll) {
            this.scrollToTarget();
        }
    }

    /**
     *
     * Scroll to defined target
     * @private
     * @memberof ScrollToDirective
     */
    private scrollToTarget() {

        const target: Element = window.document.querySelector(`#${this.domId}`);
        const targetHeight: number = target ? (target.clientHeight / 2) : 0;

        if (target) {
            const offset: number = (<HTMLElement>target).offsetTop;
            // if (offset !== window.pageYOffset) { offset = offset + (window.pageYOffset - offset); }
            animateScrollTo(offset, this.scrollOptions);
        }
    }

    /**
     * [onClick scroll to the target]
     * @param  {Event}  event [description]
     * @return void [description]
     */
    @HostListener('click') onClick(event: Event) {

        if (event) { event.preventDefault(); }
        this.scrollToTarget();
    }

}
