import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { AppResolverService } from './app-resolver.service';
import { ContainerComponent } from './container/container.component';

const appRoutes: Routes = [
    {
        path: '',
        resolve: {icon: AppResolverService},
        component: ContainerComponent,
        children: [
            {
                path: '',
                loadChildren: './home/home.module#HomeModule',
            },
            {
                path: 'legals',
                loadChildren: './legals/legals.module#LegalsModule'
            },
            {
                path: 'admin',
                loadChildren: './admin/admin.module#AdminModule',
            }
        ]
    },
    {
        path: '',
        redirectTo: '',
        pathMatch: 'full'
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(
            appRoutes,
            {
                // enableTracing: true,
                // This will tell Angular to preload the lazy-loaded routes after the
                // application has been bootstrapped. This will extend to both top-level
                // and nested lazy-loaded modules.
                preloadingStrategy: PreloadAllModules
            }
        )
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
