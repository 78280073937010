import { Component, OnInit, Input, ElementRef, Renderer2  } from '@angular/core';
import { RunOnViewportService } from '../../services/run-on-viewport.service';

@Component({
  selector: 'clv-counter',
  templateUrl: './counter.component.html',
  styleUrls: ['./counter.component.scss']
})
export class CounterComponent implements OnInit {

    @Input() from: number = 0;
    @Input() to: number;
    @Input() duration: number = 1000;

    public counter: number;
    private interval: number;
    private countInterval: number;
    private uId: string;
    private onViewPortVisibility: RunOnViewportService;
    public contentDisplay: string = '';
    private extraInfos: string = '';
    private decimal: number = 0;

    constructor(
        private element: ElementRef,
        private renderer: Renderer2,
    ) { }

    public ngOnInit() {

        if (typeof +this.to !== 'number' || isNaN(+this.to)) {

            this.extraInfos = this.to.toString().match(/\D/gi).join('').replace('.', '');
            if (!this.to.toString().match(/\d/gi)) {
                this.contentDisplay = this.to.toString();
                return;
            }

            const hasDecimal = parseFloat(this.to.toString()).toString().split('.');
            if (hasDecimal.length > 1) {
                this.decimal = +`0.${(hasDecimal.pop())}`;
            }
            this.to = +parseFloat(this.to.toString()).toString().split('.')[0];

        }

        this.uId = `count-${Date.now() + Math.random().toString().slice(2)}`;
        this.renderer.addClass(this.element.nativeElement, this.uId);
        const element = document.querySelector(`.${this.uId}`);
        this.counter = +this.from;

        this.countInterval = Math.round(this.duration / +this.to);
        this.onViewPortVisibility = new RunOnViewportService(element, this.count.bind(this));
    }

    /**
     *
     *
     * @private
     * @memberof CounterComponent
     */
    private count() {

        this.interval = window.setInterval(() => {
            if (this.counter === +this.to) {
                if (this.extraInfos && this.to.toString().split('.').length === 1) {
                    this.counter += +this.decimal;
                    this.to = this.counter; // Avoid infinite loop
                    this.contentDisplay = `${this.counter}${this.extraInfos}`;
                }
                window.clearInterval(this.interval);
                return;
            }
            this.counter += 1;
            this.contentDisplay = `${this.counter}${this.extraInfos}`;
        }, this.countInterval);
    }

}
