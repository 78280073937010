import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of, forkJoin, pipe } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AppResolverService implements Resolve<any> {

    constructor(
        private _http: HttpClient
    ) {}
    /**
     * [resolve description]
     * @param  {ActivatedRouteSnapshot}   route [description]
     * @param  {RouterStateSnapshot}      state [description]
     * @return {Observable<any>}       [description]
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {

        return this._http.get(`${window.location.origin}/assets/svgs-sprites/animate-defs.svg`, {responseType: 'text'})
        .pipe(
            tap((response) => {

                const doc = window.document.createElement('div');
                doc.setAttribute('hidden', 'true');
                doc.innerHTML = `${response}`;
                window.document.querySelector('body').appendChild(doc);
                return response;
            }),
            map((response) => response)
        );
    }
}
