import { Component, OnInit, Input } from '@angular/core';

import { IWpPageStructure } from '../../../models/wp-structure';
import { SocialNetwork } from '../../../models/social-network';
@Component({
  selector: 'clv-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

    public dateY: string;
    public socials: SocialNetwork[] = [];
    constructor() { }
    @Input()
    public data: IWpPageStructure;

    ngOnInit() {

        this.dateY = new Date().getFullYear().toString();
        this.socials = this.extractData(this.data);
    }

    /**
     *
     * Extract data for social links and icon primarily
     * @private
     * @param {IWpPageStructure} data
     * @returns {{[key: string]: SocialNetwork}}
     * @memberof FooterComponent
     */
    private extractData(data: IWpPageStructure): SocialNetwork[] {

        const socialsData = {};
        const socialKeys = this.buildSocialKeywords(this.data);

        for (const block of Object.keys(data.acf)) {

            const blockKeys = block.split('-');
            const socialLabel = blockKeys[0];

            if (blockKeys.length > 1 && socialKeys.indexOf(socialLabel) > -1) {
                if (!socialsData[socialLabel]) { socialsData[socialLabel] = {}; }
                socialsData[socialLabel][blockKeys[1]] = data.acf[block];
                if (!socialsData[socialLabel]['name']) { socialsData[socialLabel]['name'] = socialLabel; }
            }
        }
        return this.populateData(socialsData);
    }

    /**
     *
     * Convert indexed object to an array of object
     * @private
     * @param {{[key: string]: SocialNetwork}} data
     * @returns {SocialNetwork[]}
     * @memberof FooterComponent
     */
    private populateData(data: {[key: string]: SocialNetwork}): SocialNetwork[] {

        const socialItems: SocialNetwork[] = [];
        const keys = Object.keys(data);
        for (let item = 0; item < keys.length; item++) {
            socialItems.push(data[keys[item]]);
        }
        return socialItems;
    }

    /**
     *
     * Generate keys and avoid duplicate, this ease check on social keys before each addition
     * @private
     * @param {IWpPageStructure} data
     * @returns {string[]}
     * @memberof FooterComponent
     */
    private buildSocialKeywords(data: IWpPageStructure): string[] {

        const keys: string[] = [];

        for (const key of Object.keys(data.acf)) {
            const socialKeyTab: string[] = key.split('-');
            if (socialKeyTab.length > 1 && keys.indexOf(socialKeyTab[0]) === -1
            && socialKeyTab[0] !== 'exclude') { // Avoid duplication
                keys.push(socialKeyTab[0]);
            }
        }
        return keys;
    }

}
