import { Injectable } from '@angular/core';
import { debounceTime } from 'rxjs/operators';
import { fromEvent, Subscription, Observable } from 'rxjs';
import { InView } from 'inview';

export class RunOnViewportService {

    private scroll: Observable<any> = new Observable<any>();
    private scrollSubscription: Subscription = new Subscription();

    constructor(
        private element: Element,
        private callbackFunc: Function
    ) {
        this.init();
    }

    public init() {

        this.scroll = fromEvent(window, 'scroll').pipe(
            debounceTime(200),
        );

        this.scrollSubscription = this.scroll.subscribe(
            () => this.isVisibleInViewPort()
        );
    }

    /**
     *
     * Called each time the scroll is used
     * @private
     * @memberof RunOnViewportService
     */
    private isVisibleInViewPort() {

        let hasReachedVisibleViewport: boolean = false;

        const clearScroll = () => {

            if (isVisible && hasReachedVisibleViewport) {
                this.callbackFunc();
                this.scrollSubscription.unsubscribe();
                isVisible.destroy();
            }
        };

        const isVisible = InView(this.element, (isInViewport, data) => {

            if (isInViewport || data.elementOffsetTopInViewHeight < data.inViewHeight / 2) {
                this.scroll = null;
                clearScroll();
                hasReachedVisibleViewport = true;
            }
        });
    }
}
