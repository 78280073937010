import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';

import { Observable, pipe } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class MyHttpInterceptor implements HttpInterceptor {
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {

        const apiUrls: string[] = ['/wp-admin/', '/wp-json/wp/v2/pages/'];
        const isLocal = req.url.indexOf('localhost') > -1 ? true : false;
        // Makes all urls absolute
        if (isLocal && apiUrls.some((url) => req.url.indexOf(url) > -1)) {
            console.log(req.url);
            const cloneReq = req.clone({url: `${req.url.replace('localhost:4210', 'localhost:4215')}`});
            return next.handle(cloneReq);
        }

        // Avoid WP sub-directory http error
        // if (window.location.origin.indexOf('localhost') === -1 && req.url.indexOf(apiUrls[0]) !== -1) {
        //     const cloneReq = req.clone({url: `/cl-refresh/wp/${req.url}`});
        //     return next.handle(cloneReq);
        // }
        return next.handle(req);

  }
}
