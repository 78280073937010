import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'clv-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    constructor() {}

    ngOnInit() {}
}
