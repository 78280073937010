/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./container.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./container.component";
var styles_ContainerComponent = [i0.styles];
var RenderType_ContainerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContainerComponent, data: {} });
export { RenderType_ContainerComponent as RenderType_ContainerComponent };
export function View_ContainerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_ContainerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "clv-container", [], null, null, null, View_ContainerComponent_0, RenderType_ContainerComponent)), i1.ɵdid(1, 114688, null, 0, i3.ContainerComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ContainerComponentNgFactory = i1.ɵccf("clv-container", i3.ContainerComponent, View_ContainerComponent_Host_0, {}, {}, []);
export { ContainerComponentNgFactory as ContainerComponentNgFactory };
