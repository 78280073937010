import { ElementRef, Directive, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[clvSvgDraw]'
})
export class SvgDrawDirective implements OnInit {

    /**
     * [Input Svg isDecorative, checks if we should flag the svg as decorative by adding an attribute aria-hidden]
     * @param  boolean 'isDecorative' [Used an alias just for the test]
     * Refer to https://css-tricks.com/accessible-svgs/ for more infos
     */
    @Input('svgIsDecorative') isDecorative: boolean = false;
    /**
     * [Input Svg title, here we are dealing with the svg fragment (id)]
     * @param  string 'svgTitle' [Used an alias just for the test]
     */
    @Input('svgTitle') title: string = '';
    /**
     * [Input Svg id, here we are dealing with the svg symbol id]
     * @param  string 'svgId' [Used an alias just for the test]
     */
    @Input('svgId') id: string = '';

    public Vivus: any = (window as any).Vivus;

    /**
     *
     * Here is an example of usage
     * <i clvSvgDraw class="icon icon-item" [svgId]="'amazon'" ></i>
     * Is it preferable to add class icon on the container in which the svg content is injected
     *
     * @param  {ElementRef} element [description]
     * @param  {Renderer2} renderer [description]
     */
    constructor(private element: ElementRef, private renderer: Renderer2) { }

    public ngOnInit() {

        const svgSymbol = window.document.querySelector(`#shape-icon-${this.id}`);
        const svgId = Date.now() + Math.random().toString().slice(2);

        if (svgSymbol) {
            const svgContent: string = `
                <svg ${this.isDecorative ? 'aria-hidden="true" focusable="false"' : ''}
                class="icon icon-${this.id}" id="icon-${svgId}"
                ${this.addSvgProperties(svgSymbol)} role="img" xml:space="preserve">
                <title>${this.title}</title>
                ${svgSymbol.innerHTML}
                </svg>`;

            this.renderer.setProperty(this.element.nativeElement, 'innerHTML', svgContent);
            this.animIcon(svgId, svgSymbol.id);
        }
    }

    /**
     * Retrieves properties from element (SVG) and add basic svg properties on the element
     *
     * @protected
     * @param {*} element
     * @returns {string}
     * @memberof SvgDrawDirective
     */
    protected addSvgProperties(element: any): string {

        const viewBox: {[key: string]: string|number} = element.viewBox.baseVal;
        const bgEnable = `style="enable-background:new ${viewBox.x} ${viewBox.y} ${viewBox.width} ${viewBox.height}"`;
        const xmlns = `xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"`;

        return `version="1.1" ${xmlns} width="100%" height="100%" viewBox="${viewBox.x} ${viewBox.y}
        ${parseFloat(viewBox.width.toString()).toFixed(2)} ${parseFloat(viewBox.height.toString()).toFixed(2)}"`;
    }

    /**
     * Instanciate an animation on the element
     *
     * @protected
     * @param {string} svgId
     * @param {string} id
     * @memberof SvgDrawDirective
     */
    protected animIcon(svgId: string, id: string) {


        const options: {[index: string]: any} = {
            duration: 100,
            start: 'inViewport',
            type: 'sync',
            reverseStack: true,
            selfDestroy: true, // Removes all inline styles once animation finished
            animTimingFunction: this.Vivus.EASE, // Randomize this
            pathTimingFunction: this.Vivus.EASE_OUT
        };
        const callback: Function = (viusEl: any) => {

            const pathsToFill: any = [].slice.call(window.document.querySelectorAll(`#${viusEl.el.id} [fill=none]`));
            window.document.querySelector(`#${viusEl.el.id}`).classList.add('drawn');

            pathsToFill.map((el, arr, index: number) => {
                if (el.attributes.stroke && el.attributes.stroke.nodeValue) {
                    this.renderer.setAttribute(el, 'fill', el.attributes.stroke.nodeValue);
                }
            });
        };

        const drawIcon = new this.Vivus(`icon-${svgId}`, options, callback);
    }


}
