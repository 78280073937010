import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Observable, fromEvent, Subscription } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

@Component({
  selector: 'clv-smart-menu',
  templateUrl: './smart-menu.component.html',
  styleUrls: ['./smart-menu.component.scss']
})
export class SmartMenuComponent implements OnInit, OnDestroy {

    @Input() data: BasicObj[];
    @Input() excludedItems: String[] = [];

    public currentIndex: number = 0;
    public menuItems: BasicObj[] = [];
    public scroll: Observable<any> = new Observable<any>();
    public resize: Observable<any> = new Observable<any>();

    public scrollSubscription: Subscription;
    public resizeSubscription: Subscription;


    constructor() { }

    ngOnInit() {

        this.buildMenu();
        this.addListeners();
    }

    /**
     *
     * Build menu items from input data
     * @private
     * @returns void
     * @memberof SmartMenuComponent
     */
    private buildMenu() {

        if (!this.data.length) { return; }

        this.data.forEach( (item: BasicObj, index: number, array: BasicObj[]) => {

            if (this.excludedItems.indexOf(item.key) > -1) { return; }

            this.menuItems.push({key: item.key, label: item.title, id: index});
            this.updateMenuItemsOffsets(item, index);
        });

    }

    /**
     *
     * Listen to scroll and resize and force offset calculation on these events
     * @private
     * @memberof SmartMenuComponent
     */
    private addListeners() {

        this.scroll = fromEvent(window, 'scroll').pipe(
            // debounceTime(200),
            map((e: Event) => e)
        );

        this.resize = fromEvent(window, 'resize').pipe(
            debounceTime(200),
            map((e: Event) => e)
        );

        this.scrollSubscription = this.scroll.subscribe( (e) => this.updateActiveMenu() );
        // @TODO: Replace with observer API
        this.resizeSubscription = this.resize.subscribe( (e) => this.updateActiveMenu() );

        const targetNode = document.querySelector('body');
        // Options for the observer (which mutations to observe)
        const config = { attributes: true, childList: true };

        // Callback function to execute when mutations are observed
        const callback = (mutationsList) => {
            for (const mutation of mutationsList) {
                if (mutation.type === 'childList') {
                    this.updateActiveMenu();
                    // console.log('A child node has been added or removed.');
                    // console.log(mutation);
                }
            }
        };

        // Create an observer instance linked to the callback function
        const observer = new MutationObserver(callback);

        // Start observing the target node for configured mutations
        observer.observe(targetNode, config);
    }

    /**
     *
     * The name says it all
     * @private
     * @param {string} item
     * @param {number} index
     * @memberof SmartMenuComponent
     */
    private updateMenuItemsOffsets(item: BasicObj, index: number) {

        const sectionBlock: HTMLElement = <HTMLElement>window.document.querySelector(`#js-${item.key}`);
        // Avoid empty or undefined sections
        if (sectionBlock) {

            this.menuItems[index]['offset'] = {
                [`offsetTop`]: sectionBlock.offsetTop,
                [`offsetBottom`]: sectionBlock.offsetTop + sectionBlock.clientHeight
            };
        }
    }

    /**
     *
     * Set active menu
     * @private
     * @memberof SmartMenuComponent
     */
    private updateActiveMenu() {

        this.menuItems.forEach( (item, index) => { this.updateMenuItemsOffsets(item, index); } );
        // if (window.pageYOffset < this.menuItems[0].offset.offsetTop) { this.currentIndex = -1; return; }
        this.currentIndex = this.menuItems.findIndex( (item): boolean => {
            if (!item.offset) { return false; }
            if ( Math.floor(window.pageYOffset) >= item.offset['offsetTop'] &&
            Math.floor(window.pageYOffset) <= item.offset['offsetBottom'] ) {
                return true;
            }
            return  false;
        });
        // console.log(this.currentIndex);
    }

    ngOnDestroy() {
        this.scrollSubscription.unsubscribe();
        this.resizeSubscription.unsubscribe();
    }

}
