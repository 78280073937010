import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { FooterComponent } from './components/footer/footer.component';
import { SvgIconDirective } from './directives/svg-icon.directive';
import { FontObserverDirective } from './directives/font-observer.directive';
import { SvgDrawDirective } from './directives/svg-draw.directive';
import { SmartMenuComponent } from './components/smart-menu/smart-menu.component';
import { ScrollToDirective } from './directives/scroll-to.directive';
import { CounterComponent } from './components/counter/counter.component';
import { FlickitySliderDirective } from './directives/flickity-slider.directive';
import { ScrollDirectionDirective } from './directives/scroll-direction.directive';
import { HeroHeaderComponent } from '../page-components/hero-header/hero-header.component';
import { LazyLoadImageModule, intersectionObserverPreset } from 'ng-lazyload-image';
import { ContainerComponent } from '../container/container.component';

const components: any []  = [
  ContainerComponent,
  HeroHeaderComponent,
  FooterComponent,
  SvgIconDirective,
  FontObserverDirective,
  SvgDrawDirective,
  ScrollToDirective,
  SmartMenuComponent,
  CounterComponent,
  FlickitySliderDirective,
  ScrollDirectionDirective
];
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    LazyLoadImageModule.forRoot({
        preset: intersectionObserverPreset
  })
  ],
  providers: [],
  declarations: components,
  exports: components
})
export class BaseModule { }
