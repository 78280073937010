import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'clv-hero-header',
    templateUrl: './hero-header.component.html',
    styleUrls: ['./hero-header.component.scss']
})
export class HeroHeaderComponent implements OnInit {

    public coverStyles: {[index: string]: string};
    public navLinks: string[];

    @Input()
    public data: any = '';

    constructor(
        private router: Router
    ) { }

    ngOnInit() {

        this.coverStyles = {
            'image': this.data,
            'default': 'https://fakeimg.pl/3000x600/?text=Loading...&font=noto'
        };
    }

    gotoHome(link: string) {
        const route: string = link || '';
        this.router.navigate([route]);
    }
}
