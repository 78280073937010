import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// import { HttpModule } from '@angular/http';

import { BaseModule } from './core/base.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppResolverService } from './app-resolver.service';
import { MyHttpInterceptor } from './interceptors/http.interceptor';

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        // Add .withServerTransition() to support Universal rendering.
        // The application ID can be any identifier which is unique on
        // the page.
        BrowserModule.withServerTransition({appId: 'clever-mind-app'}),
        HttpClientModule,
        BrowserTransferStateModule,
        BaseModule,
        AppRoutingModule,
    ],
    providers: [{ provide: HTTP_INTERCEPTORS, useClass: MyHttpInterceptor, multi: true }, AppResolverService],
    bootstrap: [AppComponent]
})
export class AppModule { }
