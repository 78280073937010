
import { Directive, ElementRef, Input, AfterViewInit, Renderer2 } from '@angular/core';
import * as Flickity from 'flickity';

interface Obj {
    [index: string]: string;
}

interface ConfigObj {
    [index: string]: any;
}

@Directive({
selector: '[clvFlickitySlider]'
})
export class FlickitySliderDirective implements AfterViewInit {

    @Input()
    public config: ConfigObj = {};

    constructor(
        private el: ElementRef,
        private renderer: Renderer2
    ) { }

    ngAfterViewInit() {

        try {

            const defaultConfig: Object = {
                initialIndex: 0,
                prevNextButtons: true,
                cellAlign: 'center',
                contain: true,
                dragThreshold : 30,
                percentPosition: false,
                pageDots : true,
                watchCSS: true,
                tipTop: false
            };

            const uniqueClass: string = `flckty-${Date.now()}`;
            this.renderer.addClass(this.el.nativeElement, uniqueClass);
            this.config = (Object.keys(this.config).length) ? this.config : Object.assign({}, defaultConfig, this.config);
            const flickty =  new Flickity(`.${uniqueClass}`, this.config);

            window.dispatchEvent(new Event('resize'));
        } catch (e) {
            throw new Error(`${e.message}`);
        }

    }

}
