import { Directive, OnInit, ElementRef, Renderer2, Input, Inject } from '@angular/core';

@Directive({
  selector: '[clvFontObserver]'
})
export class FontObserverDirective implements OnInit {

    /**
     * [Input fonts to be loaded]
     * @param string [] 'fontStack'
     */
    @Input()
    private fontStack: string [] = [];

    /**
     * Here is an example of usage
     * <body clvFontObserver ></body>
     * @param {Renderer2} renderer
     * @memberof FontObserverDirective
     */
    constructor(
        private renderer: Renderer2
    ) {}

    ngOnInit() {

        const fontsPromise: Promise<any>[] = [];
        const FontFaceObserver = (window as any).FontFaceObserver ? (window as any).FontFaceObserver : null;

        if (this.fontStack.length && FontFaceObserver) {

            for (const font of this.fontStack) {
                fontsPromise.push(new FontFaceObserver(font).load());
            }

            Promise.all(fontsPromise).then(
                (data) =>  {
                    this.renderer.addClass(window.document.querySelector('body'), 'fonts-loaded');
                }
            );
        }
    }

}
