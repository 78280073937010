import { Directive, ElementRef, OnDestroy, AfterViewInit, Renderer2, Input } from '@angular/core';
import { Subscription, fromEvent, pipe } from 'rxjs';
import { map } from 'rxjs/operators';

enum DIRECTION {
    TOP = 'TOP',
    BOTTOM = 'BOTTOM'
}

@Directive({
    selector: '[clvScrollDirection]'
})
export class ScrollDirectionDirective implements OnDestroy, AfterViewInit {

    @Input('scrollDelay')
    public debounceTime: number = 100;

    private lastScrollyPosition: number = 0;
    private document: Document;
    private $scrollSubscription: Subscription;
    private scrollClasses: {[key: string]: string} = {
        TOP: 'scrolling-top',
        BOTTOM: 'scrolling-bottom'
    };

    constructor(private element: ElementRef, private renderer: Renderer2) {
        this.document = element.nativeElement.ownerDocument;
    }

    ngAfterViewInit() {

        // Initial position, bottom
        this.renderer.addClass(this.element.nativeElement, this.scrollClasses.BOTTOM);

        const $scrollSources = fromEvent(window, 'scroll').pipe(
            // debounceTime(this.debounceTime), // Caused lag since calculation was done
            map((data) => this.document.defaultView.pageYOffset)
        );

        this.$scrollSubscription = $scrollSources.subscribe(
            (data) => {

                const scrollDirection: DIRECTION = (this.lastScrollyPosition < +data) ? DIRECTION.BOTTOM : DIRECTION.TOP;

                if (scrollDirection === DIRECTION.TOP) {
                    this.renderer.removeClass(this.element.nativeElement, this.scrollClasses.BOTTOM);
                    this.renderer.addClass(this.element.nativeElement, this.scrollClasses.TOP);
                } else {
                    this.renderer.removeClass(this.element.nativeElement, this.scrollClasses.TOP);
                    this.renderer.addClass(this.element.nativeElement, this.scrollClasses.BOTTOM);
                }

                this.lastScrollyPosition = data;
            }
        );
    }

    ngOnDestroy() {
        this.$scrollSubscription.unsubscribe();
    }

}
