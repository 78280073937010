import { OnInit, OnDestroy } from '@angular/core';
import { Observable, fromEvent } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
var SmartMenuComponent = /** @class */ (function () {
    function SmartMenuComponent() {
        this.excludedItems = [];
        this.currentIndex = 0;
        this.menuItems = [];
        this.scroll = new Observable();
        this.resize = new Observable();
    }
    SmartMenuComponent.prototype.ngOnInit = function () {
        this.buildMenu();
        this.addListeners();
    };
    /**
     *
     * Build menu items from input data
     * @private
     * @returns void
     * @memberof SmartMenuComponent
     */
    SmartMenuComponent.prototype.buildMenu = function () {
        var _this = this;
        if (!this.data.length) {
            return;
        }
        this.data.forEach(function (item, index, array) {
            if (_this.excludedItems.indexOf(item.key) > -1) {
                return;
            }
            _this.menuItems.push({ key: item.key, label: item.title, id: index });
            _this.updateMenuItemsOffsets(item, index);
        });
    };
    /**
     *
     * Listen to scroll and resize and force offset calculation on these events
     * @private
     * @memberof SmartMenuComponent
     */
    SmartMenuComponent.prototype.addListeners = function () {
        var _this = this;
        this.scroll = fromEvent(window, 'scroll').pipe(
        // debounceTime(200),
        map(function (e) { return e; }));
        this.resize = fromEvent(window, 'resize').pipe(debounceTime(200), map(function (e) { return e; }));
        this.scrollSubscription = this.scroll.subscribe(function (e) { return _this.updateActiveMenu(); });
        // @TODO: Replace with observer API
        this.resizeSubscription = this.resize.subscribe(function (e) { return _this.updateActiveMenu(); });
        var targetNode = document.querySelector('body');
        // Options for the observer (which mutations to observe)
        var config = { attributes: true, childList: true };
        // Callback function to execute when mutations are observed
        var callback = function (mutationsList) {
            for (var _i = 0, mutationsList_1 = mutationsList; _i < mutationsList_1.length; _i++) {
                var mutation = mutationsList_1[_i];
                if (mutation.type === 'childList') {
                    _this.updateActiveMenu();
                    // console.log('A child node has been added or removed.');
                    // console.log(mutation);
                }
            }
        };
        // Create an observer instance linked to the callback function
        var observer = new MutationObserver(callback);
        // Start observing the target node for configured mutations
        observer.observe(targetNode, config);
    };
    /**
     *
     * The name says it all
     * @private
     * @param {string} item
     * @param {number} index
     * @memberof SmartMenuComponent
     */
    SmartMenuComponent.prototype.updateMenuItemsOffsets = function (item, index) {
        var _a;
        var sectionBlock = window.document.querySelector("#js-" + item.key);
        // Avoid empty or undefined sections
        if (sectionBlock) {
            this.menuItems[index]['offset'] = (_a = {},
                _a["offsetTop"] = sectionBlock.offsetTop,
                _a["offsetBottom"] = sectionBlock.offsetTop + sectionBlock.clientHeight,
                _a);
        }
    };
    /**
     *
     * Set active menu
     * @private
     * @memberof SmartMenuComponent
     */
    SmartMenuComponent.prototype.updateActiveMenu = function () {
        var _this = this;
        this.menuItems.forEach(function (item, index) { _this.updateMenuItemsOffsets(item, index); });
        // if (window.pageYOffset < this.menuItems[0].offset.offsetTop) { this.currentIndex = -1; return; }
        this.currentIndex = this.menuItems.findIndex(function (item) {
            if (!item.offset) {
                return false;
            }
            if (Math.floor(window.pageYOffset) >= item.offset['offsetTop'] &&
                Math.floor(window.pageYOffset) <= item.offset['offsetBottom']) {
                return true;
            }
            return false;
        });
        // console.log(this.currentIndex);
    };
    SmartMenuComponent.prototype.ngOnDestroy = function () {
        this.scrollSubscription.unsubscribe();
        this.resizeSubscription.unsubscribe();
    };
    return SmartMenuComponent;
}());
export { SmartMenuComponent };
